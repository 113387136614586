<template>
  <single-page>
    <div class="breadcrum"><router-link to="/">{{ $t('home') }}</router-link> / <span>{{ $t('News') }}</span></div>
    <div class="item-list">
      <div class="item" v-for="(item, index) in newsList" :key="index">
        <h3 class="item-title"><a :href="item.url" target="_blank">{{ item.title }}</a></h3>
        <p class="item-desc">{{ item.desc }} </p>
        <div class="extra">
          <span class="author">{{ item.source }}</span>
          <span class="updated_at">{{ item.date }}</span>
        </div>
      </div>
    </div>
  </single-page>
</template>

<script>
import SinglePage from './components/singlePage'
import { newsList } from '@/api/newsList'
export default {
  data() {
    return {
      newsList
    }
  },
  components: {
    SinglePage
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  }
};
</script>
<style lang="less" scoped>
.breadcrum {
  padding: 24px 0;
  font-size: 18px;

  &>a,
  &>span {
    padding: 0 0.5em;
  }
}

.item-list {
  &>.item {
    border-top: 1px dashed #3f3f3f;
    padding: 24px 0;

    &>.item-title {
      &>a {
        color: unset;
      }

      margin-bottom:8px;
    }

    &>.item-desc {
      text-indent: 0;
      opacity: 0.85;
      font-size: 16px;
      margin-bottom: 8px;
    }

    &>.extra {
      display: block;
      opacity: 0.65;
      font-size: 12px;

      &>span {
        margin-right: 2em;
      }
    }
  }

  &>.item:first {
    border: none;
  }
}</style>